/* Overrides of OpenLayers styles */
  
  .ol-control {
    padding: 0;
    border-radius: 0;
    box-shadow: 0px 1px 4px rgba(0,0,0,0.3);
    background-color: rgba(255,255,255,.4);
  }
  
  .ol-control button {
    background-color: rgba(255,255,255,1);
    color: rgba(102,102,102,1);
  }
  
  .ol-control button:hover,
  .ol-control button:focus {
    background-color: rgba(225,225,225,1);
    outline: none;
  }
  
  .ol-zoom-in {
    /* color: black; */
    /* background-color: rgba(255,255,255,1); */
    border-radius: 0 0 0 0;
  }
  
  .ol-zoom-out {
    /* color: black; */
    /* background-color: rgba(255,255,255,1); */
    border-radius: 0 0 0 0;
  }
  
  .ol-attribution {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.75rem;
    padding: 2px;
  }
  
  .ol-scale-bar, .ol-mouse-position {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  
  .ol-mouse-position {
    bottom: 24px;
    top: inherit;
    /* to match ol-scale-text */
    font-size: 14px;
    text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  }