html, body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  overflow: hidden;
  line-height: normal;
}

html, body, #root, #root > div, #root > div > div {
  height: 100%;
}

* {
  /* Firefox defaults min-width/height to 'auto' unlike Chrome, which results in wrong calculated size in flexed elements (scrolling issues) */
  min-width: 0;
  min-height: 0;
  box-sizing: unset;
}

@media screen and (min-width: 1919px) {
  html {
    font-size: calc(16px + 16 * ((100vw - 1920px) / 1920));
  }
}