/*Override amplify style*/

[data-amplify-authenticator] {
  margin-top: 2rem;
  display: grid;
  justify-content: center;
  align-content: center;
}

[data-amplify-router] {
  padding: 6rem 3rem;
}

/*SmartPort Logo*/
[data-amplify-authenticator] [data-amplify-router] {
  border: 0;
  margin-top: -4.5rem;
}

[data-amplify-authenticator] [data-amplify-container] {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  width: 385px;
}

[data-amplify-router-content]>div,
[data-amplify-authenticator-signin]>div,
[data-amplify-authenticator-resetpassword]>fieldset,
[data-amplify-authenticator-verifyuser]>fieldset,
[data-amplify-authenticator-confirmverifyuser]>fieldset,
[data-amplify-authenticator-confirmresetpassword]>fieldset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 0;
}

fieldset>button.amplify-button {
  margin-left: auto;
  margin-right: auto;
}

.amplify-input {
  padding: 14.5px 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 0;
}

.amplify-alert {
  font-size: 0.7rem;
  gap: 0.2rem;
  padding-inline: 0.2rem;
}

.amplify-alert--error {
  padding: 0.6rem;
}

.amplify-alert__dismiss {
  padding: 0;
}

.amplify-button {
  border-radius: 0;
  box-shadow: rgba(0,0,0,0.2);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.amplify-button--amplify-field-group__control--amplify-button--link--amplify-button--small {
  color: rgb(000,150,214);
  background-color: #fff;
  text-decoration: underline;
}

.amplify-alert__dismiss:hover {
  color: darkred;
  background-color: white;
}

.amplify-button--primary,
.amplify-button--primary:hover {
  width: 150px;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.amplify-button--primary {
  padding: 6px 16px;
  margin: 5px;
  font-size: 0.875rem;
  min-width: 0;
  box-sizing: border-box;
  color: #fff;
  background-color: rgb(000,150,214);
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

.amplify-field-group__outer-end .amplify-field-group__control:focus{
  background-color: #fff;
  border-color: #0af;
}

.amplify-field-group__outer-end .amplify-field-group__control:hover{
  background-color: #fff;
  border-color: black;
}

.amplify-button--primary:hover {
  background-color: rgb(0, 105, 149);
  font-weight: 500;
  line-height: 1.75;
  border-radius: 0;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 0;
}

.amplify-button--primary,
.amplify-button--primary:hover{
  width: 150px;
  padding-inline-start:0;
  padding-inline-end:0;
}

.amplify-input{
  border-radius: 0;
}

.amplify-input:focus{
  border-color: #0af;
}

.amplify-button--small,
.amplify-button--small:hover {
  background-color: #fff;
  color: rgb(000, 150, 214);
}

.amplify-button--small {
  padding: 0;
  margin-top: 0.5rem;
}

.amplify-button--small:hover {
  text-decoration: underline;
}

/* Component Styling */
.authenticatorHeader {
  text-align: center;
}

/*Sign In*/
.SignInHeader,
.ResetPassWordHeader {
  text-align: center;
}

.SignInHeader h4,
.ResetPassWordHeader h4 {
  margin-top: -2rem;
}

.amplify-heading.amplify-heading--3 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

/*Reset Password*/
.ResetPassWordHeader h5 {
  margin-bottom: 1rem;
}

.ResetPassWordHeader h6 {
  font-weight: 400;
  font-size: 1rem;
}